import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import Layout from '../components/Layout';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { YmcaAccordion } from '../components/YmcaAccordion';
import '../styles/BlogListTemplate.scss';
import '../styles/DonatePage.scss';

const DonateTemplate = ({ data }) => {
	const page = data.markdownRemark;

	useEffect(() => {
		const head = document.querySelector('head');
		const e = document.createElement('script');
		e.async = true;
		e.innerHTML = `(function() {
			window.bboxInit = function() {
				bbox.showForm('85f63edb-236c-4258-bc1c-f205c83597ae');
			};
		})()`;
		head.appendChild(e);
	});

	return (
		<Layout>
			<Helmet>
				<script
					src="https://bbox.blackbaudhosting.com/webforms/bbox-min.js"
					async
				/>
			</Helmet>
			<Navbar />
			<section className="section top-section">
				<div style={{ maxWidth: '770px', margin: '0 auto' }}>
					<div
						dangerouslySetInnerHTML={{ __html: page.html }}
						style={{ marginBottom: '2em' }}
					/>
					{/* <YmcaAccordion
						className={'ymca-accordion'}
						items={page.frontmatter.accordion_items}
						allowMultipleExpanded={true}
						allowZeroExpanded={true}
					/> */}
				</div>
			</section>
			<section className="section form-section">
				<div style={{ maxWidth: '700px', margin: '0 auto' }}>
					<div id="bbox-root" />
				</div>
			</section>
			<Footer />
		</Layout>
	);
};

export default DonateTemplate;

export const query = graphql`
	query DonatePage {
		markdownRemark(frontmatter: { slug: { eq: "donate" } }) {
			frontmatter {
				slug
				accordion_items {
					title
					content
				}
			}
			html
		}
	}
`;
